<template>
  <div class="form-box">
    <FormTitle :infoName="infoName" />
    <div class="form-list">
      <el-form :model="ruleForm" ref="ruleForm" label-position="top" class="demo-ruleForm" @submit.native.prevent>
        <el-form-item label="请输入心得体会标题">
          <el-input v-model="ruleForm.title"
                    type="text"
                    placeholder="请输入心得体会标题"
                    maxlength="50"
                    show-word-limit></el-input>
        </el-form-item>
        <div class="box-g2">
          <el-form-item class="lis-g2 lis-pc-show" label="请选择心得类型">
            <el-select filterable v-model="ruleForm.extParam.experienceType" placeholder="请选择心得类型" @change="changeType">
              <el-option
                  v-for="item in experienceInfo.type"
                  :label="item"
                  :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="lis-g2 lis-h5-show" label="请选择心得类型">
            <el-select v-model="ruleForm.extParam.experienceType" placeholder="请选择心得类型" @change="changeType">
              <el-option
                v-for="item in experienceInfo.type"
                :label="item"
                :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="lis-g2" label="请输入职业">
            <el-input v-model="ruleForm.extParam.occupation"
                      type="text"
                      placeholder="例如技术员、工程师、设计师等"
                      maxlength="20"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="心得体会背景">
          <el-input type="textarea"
            v-model="ruleForm.extParam.background"
            :rows="4"
            placeholder="描述心得体会的背景、来源情况，例如XX时间参加公司组织的学习大规模基础理论..."
            maxlength="500"
            show-word-limit></el-input>
        </el-form-item>
<!--        <el-form-item label="选择模型">-->
<!--          <el-radio-group v-model="ruleForm.extParam.modelCode">-->
<!--              <el-radio :label="1">标准版</el-radio>-->
<!--              <el-radio :label="2">-->
<!--                专业版-->
<!--                <el-tooltip class="item" effect="light" content="篇幅更长,研究领域更广,内容更精准" placement="top-start">-->
<!--                  <i class="el-icon-info" style="color: #ffaa00;"></i>-->
<!--                </el-tooltip>-->
<!--              </el-radio>-->
<!--            </el-radio-group>-->
<!--        </el-form-item>-->
        <el-form-item label="选择字数">

          <el-radio-group v-model="ruleForm.extParam.creationWordsNum" class="radio-pc-show">
            <el-radio-button v-for="(item,index) in experienceInfo.wordsNum" :label="item">{{item + '字左右'}}</el-radio-button>
          </el-radio-group>

          <el-radio-group v-model="ruleForm.extParam.creationWordsNum" class="radio-h5-show h5-el-radio">
            <el-radio v-for="item in experienceInfo.wordsNum" :label="item">{{item + '字左右'}}</el-radio>
          </el-radio-group>

        </el-form-item>
      </el-form>
    </div>
    <div class="form-btn">
      <el-checkbox class="form-check" v-model="btnInfo.checked">{{btnInfo.title}}</el-checkbox>
      <el-button style="width: 180px" type="primary" :disabled="!btnInfo.checked" @click="submitForm('ruleForm')">立即创作</el-button>
    </div>

    <NoticeWrapper />
  </div>
</template>

<script>
  import ExperienceApi from '@/api/experience'
  import CommonApi from "@/api/common"
  import { orderMixin } from '@/utils/orderMixin'
  import { validateTitle } from '@/utils/validate'
  import FormTitle from "@/components/FormTitle/index.vue"
  import NoticeWrapper from "@/components/NoticeWrapper/index.vue"
	export default {
    components: {NoticeWrapper, FormTitle},
    mixins: [orderMixin],
    props: ['infoName'],
		data() {
			return {
        ruleForm: {
          title: '',
          creationDocId: 7,
          creationDocCode: 'EXPERIENCE_REFLECTION',
          channelCode: null,
          extParam: {
            version: 1,
            // modelCode: 1,
            creationWordsNum: null,
            experienceType: '',
            occupation: '',
            background: ''
          }
        },
        // rules: {
        //   title: [
        //     { required: true, message: '请输入心得体会标题', trigger: 'blur' },
        //     { validator: validateTitle, trigger: 'blur' }
        //   ],
        //   extParam: {
        //     creationWordsNum: [
        //       { required: true, message: '请选择字数', trigger: 'change' },
        //     ]
        //   }
        // },
        btnInfo: {
          title: '我已阅读并同意: 所有的范文仅用于格式和思路参考',
          checked: true
        },
        experienceInfo: {
          type: [],
          wordsNum: []
        },
        paramsData: '', // 储存跳页面的传值
			}
		},
		created() {
      this.getType()
      this.getWordsNum()
    },
		methods: {
      //获取心得类型
      getType(){
        ExperienceApi.getType().then(res => {
          this.experienceInfo.type = res.data;
        }).catch(() => {
        })
      },
      //获取字数
      getWordsNum(){
        ExperienceApi.getWordsNum().then(res => {
          this.experienceInfo.wordsNum = res.data;
          this.ruleForm.extParam.creationWordsNum = this.experienceInfo.wordsNum[0]
        }).catch(() => {
        })
      },
      //监听选择器
      changeType(){
      },
      submitForm(formName) {
        let _this = this

        if(_this.ruleForm.title === ''){
          return _this.$message({
            message: '请输入心得体会标题',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }else if(_this.ruleForm.title.length < 5){
          return _this.$message({
            message: '标题长度需要至少5个字!',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }

        if(_this.ruleForm.extParam.experienceType === ''){
          _this.ruleForm.extParam.experienceType = '其他'
        }
        _this.paramsData = _this.ruleForm
        let info = JSON.stringify(_this.ruleForm.extParam)
        _this.ruleForm.extParam = info
        //生成订单 mixin
        _this.createOrder(_this.ruleForm,_this.paramsData)

        // this.$refs[formName].validate((valid) => {
        //   if (valid) {
        //     if(_this.ruleForm.extParam.experienceType === ''){
        //       _this.ruleForm.extParam.experienceType = '其他'
        //     }
        //     _this.paramsData = _this.ruleForm
        //     let info = JSON.stringify(_this.ruleForm.extParam)
        //     _this.ruleForm.extParam = info
        //     //生成订单 mixin
        //     _this.createOrder(_this.ruleForm,_this.paramsData)
        //   } else {
        //     return false;
        //   }
        // });
      },
      // resetForm(formName) {
      //   this.$refs[formName].resetFields();
      // }
		}
	}
</script>

<style lang="less" scoped>
@import "@/assets/css/pageContent";
.box-g2{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  .lis-g2{
    width: 50%;
    .el-select{
      width: 90%;
    }
  }
  .lis-pc-show{
    display: block;
  }
  .lis-h5-show{
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .box-g2{
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .lis-g2{
      width: 100%;
      .el-select{
        width: 100%;
      }
    }
    .lis-pc-show{
      display: none;
    }
    .lis-h5-show{
      display: block;
    }
  }
}
</style>
