import request from '@/utils/request'

export default {
  // 获取心得类型
  getType(){
    return request({
      url: '/experience/getType',
      method: 'get'
    })
  },
  // 获取字数
  getWordsNum(){
    return request({
      url: '/experience/getWordsNum',
      method: 'get'
    })
  }
}
